import { CreateBoardRequest, CreateBoardResponse, DuplicateBoardRequest, GetBoardResponse, ListBoardsResponse, ListRecentBoardsResponse } from "share2flow-typedefs"
import config from "../../config"

export async function listBoards(token: string): Promise<ListBoardsResponse> {
  const res = await fetch(`${config.apiUrl}/boards`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
  return await res.json()
}

export async function listRecentBoards(token: string, count: number): Promise<ListRecentBoardsResponse> {
  const res = await fetch(`${config.apiUrl}/boards/recent?count=${count}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
  return await res.json()
}

export async function createBoard(token: string, title: string): Promise<CreateBoardResponse> {
  const request: CreateBoardRequest = { title }

  const res = await fetch(`${config.apiUrl}/boards`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(request),
  })

  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
  return await res.json()
}

export async function getBoard(token: string, boardId: string): Promise<GetBoardResponse> {
  const res = await fetch(`${config.apiUrl}/boards/${boardId}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })

  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
  return await res.json()
}

export async function setBoardFavorite(token: string, boardId: string, isFavorite: boolean): Promise<void> {
  let res: Response

  if (isFavorite) {
    res = await fetch(`${config.apiUrl}/favorites/${boardId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    })
  } else {
    res = await fetch(`${config.apiUrl}/favorites/${boardId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    })
  }

  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
  return await res.json()
}

export async function duplicateBoard(token: string, boardId: string, title: string): Promise<CreateBoardResponse> {
  const request: DuplicateBoardRequest = { title, boardId }

  const res = await fetch(`${config.apiUrl}/boards/duplicate`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(request),
  })

  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
  return await res.json()
}

export async function deleteBoard(token: string, boardId: string): Promise<void> {
  const res = await fetch(`${config.apiUrl}/boards/${boardId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })

  if (res.status >= 400) {
    const resText = await res.text()
    throw new Error(resText)
  }
}
