export enum UserBoardRole {
  VIEWER = 'Viewer',
  COMMENTATOR = 'Commentator',
  EDITOR = 'Editor',
  OWNER = 'Owner',
}

export const rolesList = [
  UserBoardRole.VIEWER,
  UserBoardRole.COMMENTATOR,
  UserBoardRole.EDITOR,
  UserBoardRole.OWNER,
]
