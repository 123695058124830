import { createBrowserRouter, RouterProvider } from "react-router-dom"
import Board from "./features/boards/Board"
import { BoardList } from "./features/boards/BoardList"
import { AuthenticationChecker } from "./features/authentication/AuthenticationChecker"
import { Invite } from "./features/sharing/Invite"
import { UserSettings } from "./features/settings/UserSettings"
import { NotificationsSettings } from "./features/settings/NotificationsSettings"
import { SecuritySettings } from "./features/settings/SecuritySettings"
import { Settings } from "./features/settings/Settings"
import { Login } from "./features/authentication/Login"
import { MainLayout } from "./MainLayout"
import { Signup } from "./features/authentication/Signup"
import { Videos } from "./features/videos/Videos"
import { MyFlows } from "./features/boards/MyFlows"
import { Todo } from "./features/todo/Todo"
import { Billing } from "./features/billing/Billing"
import { Subscription } from "./features/billing/Subscription"
import { History } from "./features/billing/History"
import { RecoverPassword } from "./features/authentication/RecoverPassword"
import { Notifications } from "./features/notifications/Notifications"
import { Statistics } from "./features/statistics/Statistics"
import BoardPrint from "./features/boards/BoardPrint"
import { GoogleCallback } from "./features/authentication/GoogleCallback"
import { Upgrade } from "./features/billing/Upgrade"
import { Management } from "./features/management/Management"
import { ManagementUsers } from "./features/management/Users"
import { DeleteAccountSettings } from "./features/settings/DeleteAccountSettings"

const router = createBrowserRouter([
  {
    path: '/auth/login',
    element: <Login />,
  },
  {
    path: '/auth/signup',
    element: <Signup />,
  },
  {
    path: '/auth/recover-password',
    element: <RecoverPassword />,
  },
  {
    path: '/auth/google/callback',
    element: <GoogleCallback />,
  },
  {
    path: '/board-print/:boardId',
    element: <BoardPrint />,
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <BoardList />,
        children: [
        ],
      },
      {
        path: 'boards/:boardId',
        element: <Board />,
      },
      {
        path: 'invites/:inviteId',
        element: <Invite />,
      },
      {
        path: 'my-flows',
        element: <MyFlows />,
      },
      {
        path: 'videos',
        element: <Videos />,
      },
      {
        path: 'to-do',
        element: <Todo />,
      },
      {
        path: 'notifications',
        element: <Notifications />,
      },
      {
        path: 'statistics',
        element: <Statistics />,
      },
      {
        path: 'settings',
        element: <Settings />,
        children: [
          {
            path: '',
            element: <UserSettings />,
          },
          {
            path: 'notifications',
            element: <NotificationsSettings />,
          },
          {
            path: 'security',
            element: <SecuritySettings />,
          },
          {
            path: 'delete-account',
            element: <DeleteAccountSettings />,
          },
        ],
      },
      {
        path: 'billing',
        element: <Billing />,
        children: [
          {
            path: 'subscription',
            element: <Subscription />,
          },
          {
            path: 'history',
            element: <History />,
          },
          {
            path: 'upgrade',
            element: <Upgrade />,
          },
        ],
      },
      {
        path: 'management',
        element: <Management />,
        children: [
          {
            path: '',
            element: <></>,
          },
          {
            path: 'users',
            element: <ManagementUsers />,
          },
        ],
      },
    ],
  },
])

export function AppRouter() {
  return (
    <>
      <RouterProvider router={router} />
      <AuthenticationChecker router={router} />
    </>
  )
}
