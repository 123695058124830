import { Fragment } from "react"
import { Transition, Dialog as HDialog } from "@headlessui/react"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Avatar } from "../../components/controls/Avatar"
import { Dialog } from "../../components/controls/Dialog"
import { LoadingState } from "../../components/controls/LoadingState"
import { RootState } from "../../store"
import { formatDateLong } from "../../utils"
import { NotificationMessageType, showNotificationMessage } from "../notificationMessages/notificationMessagesSlice"
import { getSubscription, listUsers, setUserActive, deleteUser } from "./api"

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}

export function ManagementUsers() {
  const { t, i18n } = useTranslation()
  const token = useSelector((state: RootState) => state.authentication.token)

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [users, setUsers] = useState<any[]>([])
  const [selectedUser, setSelectedUser] = useState<any>(null)
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [subscription, setSubscription] = useState<any>(null)
  const [showDeleteUserDialog, setShowDeleteUserDialog] = useState<boolean>(false)

  const fetchUsers = async () => {
    try {
      const users = await listUsers(token!)
      setUsers(users)
      if (selectedUser) {
        const user = users.find((u) => u.id === selectedUser.id)
        if (user) {
          setSelectedUser(user)
          fetchSubscription(user.subscriptionId)
        }
      }
    } catch (e: any) {
      showNotificationMessage({
        type: NotificationMessageType.Error,
        title: t('Error fetching users'),
        body: e.toString(),
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  const fetchSubscription = async (id: string) => {
    setSubscription(null)
    try {
      const subscription = await getSubscription(token!, id)
      setSubscription(subscription)
    } catch (e: any) {
      showNotificationMessage({
        type: NotificationMessageType.Error,
        title: t('Error fetching subscription'),
        body: e.toString(),
      })
    }
  }

  const activateUser = async (id: string, active: boolean) => {
    await setUserActive(token!, id, active)
    await fetchUsers()
  }

  const selectUser = (user: any) => {
    setSelectedUser(user)
    fetchSubscription(user.subscriptionId)
    setDialogOpen(true)
  }

  const confirmDeleteSelectedUser = async () => {
    if (selectedUser) {
      try {
        await deleteUser(token!, selectedUser.id)
      } catch (e: any) {
        showNotificationMessage({
          type: NotificationMessageType.Error,
          title: t('Error deleting user'),
          body: e.toString(),
        })
      }
      setSelectedUser(null)
      setDialogOpen(false)
      await fetchUsers()
    }
  }

  return (
    <main className="flex justify-center">
      <div className="w-full max-w-7xl">
        <LoadingState isLoading={isLoading}>
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle">
                  <table className="min-w-full border-separate border-spacing-0">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                        >
                          {t('Name')}
                        </th>
                        <th
                          scope="col"
                          className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                        >
                          {t('Email')}
                        </th>
                        <th
                          scope="col"
                          className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                        >
                          {t('Signed up')}
                        </th>
                        <th
                          scope="col"
                          className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                        >
                          {t('Role')}
                        </th>
                        <th
                          scope="col"
                          className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                        >
                          {t('Active')}
                        </th>
                        <th
                          scope="col"
                          className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                        >
                          <span className="sr-only">{t('View')}</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map((user, userIdx) => (
                        <tr key={user.email}>
                          <td
                            className={classNames(
                              userIdx !== users.length - 1 ? 'border-b border-gray-200' : '',
                              'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8',
                            )}
                          >
                            <div className="flex items-center">
                              <Avatar avatarUrl={user.avatarUrl} size={24} />
                              <span className="ml-2">{user.name}</span>
                            </div>
                          </td>
                          <td
                            className={classNames(
                              userIdx !== users.length - 1 ? 'border-b border-gray-200' : '',
                              'hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell',
                            )}
                          >
                            {user.email}
                          </td>
                          <td
                            className={classNames(
                              userIdx !== users.length - 1 ? 'border-b border-gray-200' : '',
                              'hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell',
                            )}
                          >
                            {formatDateLong(i18n.language, new Date(user.insertedAt))}
                          </td>
                          <td
                            className={classNames(
                              userIdx !== users.length - 1 ? 'border-b border-gray-200' : '',
                              'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
                            )}
                          >
                            {user.isAdmin ? 'Admin' : 'User'}
                          </td>
                          <td
                            className={classNames(
                              userIdx !== users.length - 1 ? 'border-b border-gray-200' : '',
                              'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
                            )}
                          >
                            {user.isActive ? t('Yes') : t('No')}
                          </td>
                          <td
                            className={classNames(
                              userIdx !== users.length - 1 ? 'border-b border-gray-200' : '',
                              'relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-8 lg:pr-8',
                            )}
                          >
                            <a className="text-indigo-600 hover:text-indigo-900 cursor-pointer" onClick={() => selectUser(user)}>
                              {t('View')}<span className="sr-only">, {user.name}</span>
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </LoadingState>
      </div>

      <Dialog
        title={t('User details')}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        controls={[{ id: 'toggleActivated', text: selectedUser?.isActive ? 'Deactivate' : 'Activate', onClick: () => activateUser(selectedUser?.id, !selectedUser?.isActive) }, { id: 'delete', text: 'Delete', isDanger: true, onClick: () => { setShowDeleteUserDialog(true) }}]}
      >
        <div>
          <div className="mt-6">
            <dl className="divide-y divide-gray-100">
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">{t('Name')}</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  <div className="flex items-center">
                    <Avatar avatarUrl={selectedUser?.avatarUrl} size={24} />
                    <span className="ml-2">{selectedUser?.name}</span>
                  </div>
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">{t('Email address')}</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{selectedUser?.email}</dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">{t('Active')}</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{selectedUser?.isActive ? "Yes" : "No"}</dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">{t('Signed up')}</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{selectedUser?.insertedAt && formatDateLong(i18n.language, new Date(selectedUser?.insertedAt || ''))}</dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">{t('Last login')}</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{selectedUser?.lastLoggedInAt && formatDateLong(i18n.language, new Date(selectedUser?.lastLoggedInAt))}</dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">{t('Subscription plan')}</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{subscription?.planId}</dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">2FA</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex gap-2">
                  {selectedUser?.appMFA &&
                    <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                      App
                    </span>
                  }
                  {selectedUser?.smsMFA &&
                    <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                      SMS
                    </span>
                  }
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </Dialog>

      <Transition.Root show={showDeleteUserDialog} as={Fragment}>
        <HDialog as="div" className="relative z-[1001]" onClose={() => setShowDeleteUserDialog(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <HDialog.Panel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg border-2 border-purple-700">
                  <div className="m-3 sm:m-5">
                    <HDialog.Title as="h3" className="text-xl font-semibold leading-6 text-gray-900">
                      {t('Are you sure you want to delete this user?')}
                    </HDialog.Title>
                    <div className="mt-3 text-sm text-gray-500">
                      <p>{t('This will delete the user account and all associated data, including boards owned by the user. To keep these boards, please have the user transfer ownership of them first. This action is destructive and cannot be undone.')}</p>
                    </div>
                    <div className="mt-5 sm:mt-6 sm:flex flex-row-reverse sm:gap-3">
                      <button
                        type="button"
                        className="flex-1 inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                        onClick={() => { confirmDeleteSelectedUser(); setShowDeleteUserDialog(false) }}
                      >
                        {t('Delete User')}
                      </button>
                      <button
                        type="button"
                        className="flex-1 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                        onClick={() => setShowDeleteUserDialog(false)}
                      >
                        {t('Cancel')}
                      </button>
                    </div>
                  </div>
                </HDialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </HDialog>
      </Transition.Root>
    </main>
  )
}
