import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { BoardItem, UserBoardRole } from "share2flow-typedefs"
import { LoadingState } from "../../components/controls/LoadingState"
import { AppDispatch, RootState } from "../../store"
import { signOutUser } from "../authentication/authenticationSlice"
import { listBoards } from "../boards/api"
import { deleteAccount } from "./api"

export function DeleteAccountSettings() {
  const { t } = useTranslation()
  const token = useSelector((state: RootState) => state.authentication.token)
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [boards, setBoards] = useState<BoardItem[]>([])
  const [isOtpRequested, setIsOtpRequested] = useState<boolean>(false)
  const [otp, setOtp] = useState<string>('')
  const [error, setError] = useState<string>('')

  useEffect(() => {
    const fetchOwnedBoards = async () => {
      const boards = await listBoards(token!)
      setBoards(boards)
      setIsLoading(false)
    }
    fetchOwnedBoards()
  }, [])

  const requestDeleteAccount = async () => {
    try {
      if (!isOtpRequested) {
        const res = await deleteAccount(token!)
        if (res.state == "otp-required") {
          setError('')
          setIsOtpRequested(true)
          return
        }
      }
      if (otp === '') {
        setError(t('Please enter the OTP to confirm the deletion of your account.'))
        return
      }
      const res = await deleteAccount(token!, otp)
      if (res.state == "otp-failure") {
        setError(t('The OTP you entered is incorrect. Please try again.'))
        return
      }
      dispatch(signOutUser())
      navigate('/login')
    } catch (e: any) {
      setError(e.toString())
    }
  }

  return (
    <main className="main-section">
      <div className="container">
        <div className="inner_container">
          <div className="pb-5">
            <h3 className="fs_lg c_gray mb-4 fw_medium">
              {t('Delete Account')}
            </h3>
            <p>{t('Are you sure you want to delete your account?')}</p>
            <p>{t('Deleting your account will wipe all of your personal data from our servers.')}</p>
            <p>{t('Additionally, all boards that you own will be deleted')}. {t('If you want to keep any of the following boards, please transfer the ownership of them to a different user')}:</p>
            <LoadingState isLoading={isLoading}>
              <ul>
                {boards.filter(board => board.role == UserBoardRole.OWNER).map(board => <li key={board.id}><Link to={`/boards/${board.id}`}>{board.title}</Link></li>)}
              </ul>
            </LoadingState>
            <p>{t('Please note that this action is irreversible.')}</p>
            <p>{t('If you are sure you want to delete your account, please click the button below.')}</p>
            {isOtpRequested &&
              <div className="my-4">
                <div className="col-md-5 col-xl-4">
                  <div className="inp_label fs_lg fw_medium">
                    {t('OTP')}
                  </div>
                </div>
                <div className="col-md-7  col-xl-8">
                  <input className="form-control fs_lg def_inp" type="text" value={otp} onChange={(e) => setOtp(e.target.value)} />
                </div>
                <p className="mt-2">
                  {t('An OTP has been sent to your email address. Please enter it above to confirm the deletion of your account.')}
                </p>
              </div>
            }
            {error && <p className="text-danger">{error}</p>}
            <button className="btn btn-danger" onClick={requestDeleteAccount}>{t('Delete Account')}</button>
          </div>
        </div>
      </div>
    </main>
  )
}
